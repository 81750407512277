// C:\Users\james\Portfolio\src\data\projectsData.js
export const dummyProjects = [
  {
  id: "censorly",
  title: "Censorly",
  category: "Web Platform",
  summary: "An inclusive, creator-first platform built to empower artists and content creators with full creative freedom and fair monetization options.",
  image: "/assets/images/censorly-cover.jpg",
  tags: ["TS", "Nextjs", "MongoDB", "AWS", "Payment Integration", "Content Management"],
  featured: true,
  fullDescription: `Censorly: A Platform for Forgotten Creators
I created Censorly because I saw a real problem in the creator space. Too many talented artists and content creators were getting pushed off mainstream platforms, losing their income streams and connections with their supporters overnight. While platforms like Patreon and OnlyFans serve many creators well, there's a significant group being left behind – and that's where Censorly comes in.
This platform is my answer to a simple question: where do creators go when other platforms shut them out? I'm building Censorly to be that place – a home for creators who've been banned or restricted elsewhere, where they can rebuild their communities and continue earning from their work.
At its heart, Censorly is straightforward. Creators can share their content, set up membership tiers, and connect with their supporters. I've focused on making it reliable and secure, with features like:

Flexible membership tiers for different content access levels
Direct messaging between creators and supporters
Solid payment processing that supports Adult content creators too
Simple but effective content management
Clear analytics so creators can track their growth

The platform isn't trying to compete with the giants – instead, it's filling a gap they've left behind. Yes, there are content guidelines and age verification systems in place, but the focus is on giving creators the freedom to express themselves while maintaining necessary protections.
I'm still actively developing Censorly, adding features and refining the experience based on creator feedback. The goal isn't to be the biggest platform out there, but to be the most reliable option for creators who need an alternative.
If you're interested in following the development or want to learn more about using Censorly, feel free to reach out.`,
  media: {
    prototype: [
      "/assets/images/censorly-dashboard.jpg",
      "/assets/images/censorly-creator-profile.jpg",
      "/assets/images/censorly-analytics.jpg",
      "/assets/images/censorly-content-management.jpg"
    ],
  },
  status: "Early release mid february"
},
  {
    id: "apt-finder",
    title: "Apt Finder",
    category: "Mobile Development",
    summary: "An AR-powered solution that helps delivery drivers easily locate apartment numbers using their phone camera.",
    image: "/assets/images/apt-finder-cover.jpg",
    tags: ["AR", "Mobile", "React"],
    featured: true,
    fullDescription: `Back when I used to deliver for DoorDash during the pandemic, I dreaded apartment orders. It felt like navigating a torturous maze just to deliver a $5 order to a third-floor apartment—and, of course, the apartment number was never the one I needed when I got there.
That frustration sparked a whole new idea: what if I could just hold up my phone, aim it at any building, and instantly see the apartment numbers in 3d space, It would be like playing Pokémon GO, but instead of chasing down virtual creatures, I’d be tracking down real-life addresses.
The concept—Apt Finder—would use AR technology to overlay addresses in 3D space, making it simple to find your way in complex locations. While I'm excited about the possibilities, I also know it's an ambitious project that will take significant time and resources to bring to life.

For now, Apt Finder is on the back burner, but it's a dream I'm determined to pursue when the time is right. Until then, I'll keep brainstorming and refining the concept. Who knows? Maybe one day it'll be a game-changer for delivery drivers everywhere.`,
    media: {
      prototype: [
        "/assets/images/apt-finder-proto-1.jpg",
        "/assets/images/apt-finder-proto-2.jpg",
        "/assets/images/apt-finder-proto-3.jpg"
      ],
      video: "/assets/videos/apt-finder-demo.mp4",
      figmaEmbed: "https://embed.figma.com/proto/GG1DAjjK21ZXL4qvAiKhng/Aptfinder-design-V2?node-id=1-4&starting-point-node-id=1%3A4&embed-host=share"
    },
    status: "In Development"
  },
  {
    id: "nuti-pocket",
    title: "Nuti-Pocket",
    category: "Mobile Development",
    summary: "A smart nutrition tracking app that provides real-time nutritional information for your meals using weight measurements.",
    image: "/assets/images/nuti-poket-cover.jpg",
    tags: ["React", "Physical Product", "Health Tech", "Mobile App"],
    featured: true,
    fullDescription: `When I was a kid, I spent countless hours playing games like Fallout and Skyrim. One of my favorite things was how every item you picked up had its own stats. A loaf of bread restored 20 HP, and a honey bun gave you a +20 stamina boost. In games, it was simple: everything you consumed had clear, immediate effects.

In real life, though, it’s not so straightforward. We know the food we eat impacts our health and energy, but figuring out exactly how? That’s a whole other challenge. Nutritional labels are either too complex or too easy to overlook, leaving us guessing—or hoping—we’re making the right choices.

That’s what inspired me to create Nuti-Pocket, a tool designed to bring that same “visible stats” experience from games into our everyday lives. Here’s the idea: weigh your meal, sync the data with Nuti-Pocket, and—boom—you get a full nutritional breakdown on your phone. No more squinting at labels or deciphering ingredient lists. Just a clean, game-like stats display for your food.

Since first dreaming this up back in 2016, some companies have tried similar ideas. But I believe there’s still room to do it better. My goal is for Nuti-Pocket to feel intuitive and engaging. If we can make nutritional info easy to understand—and even fun—we can empower people of all generations to make healthier, more informed decisions about what they put into their bodies.

I’m currently in the prototyping phase, refining features and testing the concept. There’s still much work to be done, and I’m just one person—but this is a project I’m truly excited to bring to life.`,
    media: {
      prototype: [
        "/assets/images/nuti-poket-proto-1.jpg",
        "/assets/images/nuti-poket-proto-2.jpg",
        "/assets/images/nuti-poket-proto-3.jpg",
        "/assets/images/nuti-poket-proto-4.jpg"
      ],
      video: "/assets/videos/nuti-poket-demo.mp4",
      figmaEmbed: "https://embed.figma.com/proto/JaTTJJmkKe0OwobnB3Fw10/Untitled?node-id=172-18&starting-point-node-id=251%3A247&embed-host=share"
    },
    status: "Prototype Phase"
  },
 {
    id: "ev-taxi",
    title: "EV Taxi",
    category: "Mobile Development",
    summary: "Cross-platform mobile app delivering a seamless experience for drivers and passengers.",
    image: "/assets/images/ev-taxi-cover.jpg", 
    tags: ["React", "Heroku", "Real-Time Data"],
    featured: true,
    fullDescription: `I'm developing EV (Easy Vehicle) Taxi, a custom app that's transforming how rideshare drivers build their independent businesses. After hearing about the daily challenges from a friend who drives for Uber unclear routes, unpredictable requests, and platform fees eating into earnings I decided to create a solution that puts drivers first.
What makes EV Taxi different is its focus on building lasting customer relationships. Drivers can convert passengers from events, rideshare platforms, or word of mouth into direct, recurring clients. The best part? Drivers keep 100% of their earnings, paying just a one-time fee for their personalized app.
The driver experience includes all the essentials: clear pickup points, destinations, fare estimates, distance calculations, and efficient route planning. The dashboard is streamlined for quick decision making accept or decline rides instantly, view your driving history, and manage your availability.
For passengers, booking remains simple: enter pickup and drop-off locations, see the fare upfront, and request your ride. But unlike traditional rideshare apps, passengers can build a direct relationship with their preferred drivers, ensuring consistent service quality and reliability.
Built with React and supported by a robust cloud backend, the platform is designed for scalability. Future updates will include advanced analytics, EV charging station integration, and expanded booking features. I'm continuously working with drivers to refine the app, ensuring it genuinely enhances their business potential and daily operations.
This project represents more than just another taxi app—it's a path to independence for rideshare drivers, helping them build their own customer base and take control of their earnings.`,
    media: {
      prototype: [
        "/assets/images/ev-taxi-dashboard.jpg",
        "/assets/images/ev-taxi-route.jpg",
        "/assets/images/ev-taxi-earnings.jpg",
        "/assets/images/ev-taxi-charging.jpg"
      ],
      video: "/assets/videos/ev-taxi-demo.mp4"
    },
    status: "In Development"
}
];
