import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { BackToTop } from '../../common/components/UIElements';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  // Scrolls to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Privacy Policy | [BR6]</title>
        <meta name="description" content="Privacy Policy for [BR6] website" />
      </Helmet>

      <div className="privacy-policy">
        <Container>
          <div className="privacy-policy__content">
            <h1>Privacy Policy</h1>
            <p className="last-updated">Last Updated: January 12, 2024</p>

            <section>
              <h2>1. Introduction</h2>
              <p>
                Welcome to [BR6] ("we," "our," or "us"). We respect your privacy
                and are committed to protecting your personal data. This privacy
                policy explains how we collect, use, and safeguard your
                information when you visit our website.
              </p>
            </section>

            <section>
              <h2>2. Information We Collect</h2>
              <h3>2.1 Information You Provide</h3>
              <p>
                We collect information that you voluntarily provide when using
                our services, including:
              </p>
              <ul>
                <li>Contact information (name, email address, phone number)</li>
                <li>Account credentials</li>
                <li>Content you create, upload, or share</li>
                <li>Communication preferences</li>
                <li>Payment information</li>
              </ul>

              <h3>2.2 Automatically Collected Information</h3>
              <p>
                We automatically collect certain information when you visit our
                website:
              </p>
              <ul>
                <li>Device information (IP address, browser type, operating system)</li>
                <li>Usage data (pages visited, time spent, clicks)</li>
                <li>Location data</li>
                <li>Cookies and similar tracking technologies</li>
              </ul>
            </section>

            <section>
              <h2>3. How We Use Your Information</h2>
              <p>We use your information for the following purposes:</p>
              <ul>
                <li>Providing and maintaining our services</li>
                <li>Processing your transactions</li>
                <li>Communicating with you about our services</li>
                <li>Improving our website and user experience</li>
                <li>Analyzing usage patterns and trends</li>
                <li>Protecting against fraud and abuse</li>
                <li>Complying with legal obligations</li>
              </ul>
            </section>

            <section>
              <h2>4. Data Security</h2>
              <p>
                We implement appropriate technical and organizational security
                measures to protect your personal information. However, no
                method of transmission over the Internet is 100% secure, and we
                cannot guarantee absolute security.
              </p>
            </section>

            <section>
              <h2>5. Data Retention</h2>
              <p>
                We retain your personal information only for as long as
                necessary to fulfill the purposes outlined in this privacy
                policy, unless a longer retention period is required by law.
              </p>
            </section>

            <section>
              <h2>6. Your Rights</h2>
              <p>You have the following rights regarding your personal information:</p>
              <ul>
                <li>Right to access your personal data</li>
                <li>Right to correct inaccurate data</li>
                <li>Right to request deletion of your data</li>
                <li>Right to restrict processing</li>
                <li>Right to data portability</li>
                <li>Right to object to processing</li>
              </ul>
            </section>

            <section>
              <h2>7. Cookies Policy</h2>
              <p>
                We use cookies and similar tracking technologies to enhance your
                browsing experience. You can control cookie settings through
                your browser preferences.
              </p>
            </section>

            <section>
              <h2>8. Third-Party Links</h2>
              <p>
                Our website may contain links to third-party websites. We are
                not responsible for the privacy practices or content of these
                sites.
              </p>
            </section>

            <section>
              <h2>9. Children's Privacy</h2>
              <p>
                Our services are not intended for users under 18 years of age.
                We do not knowingly collect or maintain information from
                children.
              </p>
            </section>

            <section>
              <h2>10. Changes to This Policy</h2>
              <p>
                We may update this privacy policy from time to time. We will
                notify you of any changes by posting the new policy on this
                page.
              </p>
            </section>

            <section>
              <h2>11. Contact Me</h2>
              <p>If you have questions about this privacy policy, please contact us at:</p>
              <p>Email: Jamesalxlucas@gmail.com</p>
            </section>
          </div>
        </Container>
        <BackToTop />
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
