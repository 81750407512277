// C:\Users\james\Portfolio\src\common\components\Footer\Footer.jsx
import React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './Footer.scss';

const Footer = () => {
  return (
    <div id="footer">
      <footer>
        <Row>
          <Col sm>
            {/* Removed visits counter section */}
          </Col>
        </Row>
        <span className="divider"></span>
        <Row className="extras">
          <Col sm>
            <Stack>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Stack>
          </Col>
        </Row>
      </footer>
      <Stack className="copyright" direction="horizontal" gap={5}>
        <p>© 2021 - 2025 [BR6] All rights reserved.</p>
        <p className="ms-auto">v1.7.0</p>
      </Stack>
    </div>
  );
};

export default Footer;