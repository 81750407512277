// src/common/components/UIElements/AnimatedCounter.jsx
import React, { useRef } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const AnimatedCounter = ({ end, duration }) => {
  const counterRef = useRef(null);

  return (
    <CountUp end={end} duration={duration}>
      {({ countUpRef, start }) => (
        <VisibilitySensor onChange={start} delayedCall>
          <span ref={counterRef}>
            <span ref={countUpRef} />
          </span>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export default AnimatedCounter;